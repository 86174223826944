import styles from "./quality.module.css";

function Quality() {
  return (
    <div className={styles.quality}>
      <div className={`container ${styles.main}`}>
        <div className={styles.boxx}>
          <div className={styles.icon}>
            <ion-icon name="shield-checkmark-outline"></ion-icon>
          </div>
          <div>
            <p className={styles.lead}>Best and Reliable</p>
            <p className={styles.body}>
              Your trusted source for top-quality petroleum products, we stand
              as the industry's best and most reliable choice.
            </p>
          </div>
        </div>
        <div className={styles.boxx}>
          <div className={styles.icon}>
            <ion-icon name="happy-outline"></ion-icon>
          </div>
          <div>
            <p className={styles.lead}>Consumer Assurance</p>
            <p className={styles.body}>
              At our petroleum company, customer assurance is our top priority.
              We are dedicated to delivering reliable products and services,
              ensuring peace of mind for our valued customers
            </p>
          </div>
        </div>
        <div className={styles.boxx}>
          <div className={styles.icon}>
            <ion-icon name="time-outline"></ion-icon>
          </div>
          <div>
            <p className={styles.lead}>24 Hrs Service</p>
            <p className={styles.body}>
              Experience seamless support with our 24/7 service, providing
              round-the-clock assistance for all your petroleum needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quality;
