import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./carousel.module.css";

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1140, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%", overflow: "hidden", textAlign: "left" }}>
      <Slider {...settings}>
        {/* first */}
        <div className={styles.card}>
          <div className={styles.tank}></div>
          <div>
            <p className={styles.lead}>Oil/Gas Supply & Retail</p>
            <p className={styles.body}>
              Seamlessly bridging the gap between supply and retail in the oil
              and gas sector, we ensure a reliable and efficient source for your
              energy needs. Trust us to deliver quality products and services,
              driving your success in this dynamic industry.
            </p>
          </div>
        </div>
        {/* second */}
        <div className={styles.card}>
          <div className={styles.supply}></div>
          <div>
            <p className={styles.lead}>LPG Supply</p>
            <p className={styles.body}>
              Reliable and efficient, our LPG supply service ensures a
              consistent source for your energy needs. Count on us for quality
              and dependability in every delivery, powering your operations
              seamlessly.
            </p>
          </div>
        </div>
        {/* third */}
        <div className={styles.card}>
          <div className={styles.repair}></div>
          <div>
            <p className={styles.lead}>Car Repair</p>
            <p className={styles.body}>
              At our auto repair center, we provide expert solutions for all
              your car repair needs. From diagnostics to meticulous repairs,
              trust us to keep your vehicle running smoothly. Your satisfaction
              and your car's performance are our top priorities.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.wash}></div>
          <div>
            <p className={styles.lead}>Car Wash</p>
            <p className={styles.body}>
              Elevate your vehicle's shine with our professional car wash
              service. Our meticulous attention to detail ensures a spotless
              finish, leaving your car looking its best. Experience the ultimate
              in convenience and cleanliness with our dedicated car wash
              solutions.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.consult}></div>
          <div>
            <p className={styles.lead}>
              Oil & Gas Production <br /> Operation Consultant
            </p>
            <p className={styles.body}>
              Our consulting services offer strategic insights and solutions.
              From reservoir analysis to operational optimization, we empower
              your success in the dynamic energy sector. Partner with us for
              expert E&P consulting that drives efficiency and maximizes
              returns.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.image2}></div>
          <div>
            <p className={styles.lead}>Home Cleaning Services</p>
            <p className={styles.body}>
              Transform your living space with our professional home cleaning
              services. Our meticulous and reliable team ensures a spotless and
              refreshed environment, giving you the comfort and cleanliness you
              deserve. Experience the convenience of a sparkling home without
              the hassle.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.labour}></div>
          <div>
            <p className={styles.lead}>Labour Supply Services</p>
            <p className={styles.body}>
              Empower your workforce with our reliable Labor Supply Services. We
              specialize in providing skilled and dedicated manpower tailored to
              your industry needs. Count on us for a seamless integration of
              competent labor, ensuring efficiency and productivity in your
              operations.
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.logistics}></div>
          <div>
            <p className={styles.lead}>Logistics Services</p>
            <p className={styles.body}>
              Empower your business with our comprehensive logistics services.
              From seamless supply chain management to efficient transportation
              solutions, we optimize every step of the process. With a
              commitment to reliability and precision, we ensure your goods
              reach their destination on time, every time.
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
