import About from "./../../components/about/About";
import Footer from "./../../components/footer/Footer";
import Landing from "./../../components/landingpage/Landing";
import Navbar from "./../../components/navbar/Navbar";
import Offer from "./../../components/offer/Offer";
import Quality from "./../../components/quality/Quality";
import Subscribe from "./../../components/subscribe/Subscribe";
import Testimonials from "./../../components/testimonial/Testimonials";
import Top from "./../../components/topdetails/Top";

function Home() {
  return (
    <div>
      <Top />
      <Navbar />
      <Landing />
      <Quality />
      <About />
      <Offer />
      <Testimonials />
      <Subscribe />
      <Footer />
    </div>
  );
}

export default Home;
