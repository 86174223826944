import styles from "./landing.module.css";

function Landing() {
  return (
    <div className={styles.bg}>
      <p className={styles.lead}>Your Premier Partner in Oil Excellence</p>
    </div>
  );
}

export default Landing;
