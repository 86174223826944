import styles from "./signup.module.css";
import fecho from "./../../assets/fecho.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import app from "./../../config/firebase";

function Signup() {
  const [firstname, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const signup = () => {
    const auth = getAuth();
    setIsLoading(true);

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        updateProfile(user, {
          displayName: firstname,
        })
          .then(() => {
            // Profile updated successfully
            console.log("User created with additional details:", user);
            setError("Account created Successfully.");

            setTimeout(() => {
              navigate("/home");
            }, 2000);
          })
          .catch((error) => {
            // An error occurred while updating the user profile
            console.error("Error updating user profile:", error);
          })
          .finally(() => {
            setIsLoading(false); // Set loading to false after processing
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.signin}>
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.spinner}></div>
        </div>
      )}
      <div className={`container ${styles.main}`}>
        <img src={fecho} alt="" width={100} />
        <div className={styles.form}>
          <h2 className={styles.lead}>Login</h2>
          <input
            className={styles.field}
            type="text"
            placeholder="First Name"
            id="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            required
          />{" "}
          <br />
          <input
            className={styles.field}
            type="text"
            placeholder="Email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <br />
          <input
            className={styles.field}
            type="password"
            placeholder="Password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <br />
          <input
            className={styles.submit}
            type="submit"
            onClick={signup}
            value="Register"
          />
        </div>

        <p style={{ marginTop: "30px" }}>
          Already have an account?{" "}
          <Link className={styles.create} to="/">
            Login
          </Link>
        </p>
        {/* Error message */}
        {error && (
          <div className={styles.message}>
            {error} <br />
            <span className={styles.red}> Redirecting to Home...</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Signup;
