import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./testimonial.module.css";
import quote from "./../../assets/image3.png";

function Testimonials() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <div
      className={`container`}
      style={{
        width: "100%",
        margin: "auto",
        marginBottom: "50px",
        overflow: "hidden",
        textAlign: "center",
      }}
    >
      <div className={styles.intro}>
        <h2 className={styles.lead}>TESTIMONIAL</h2>
        <p>What our customer say</p>
      </div>
      <Slider {...settings}>
        {/* first */}
        <div className={styles.card}>
          <img src={quote} alt="" width={30} />
          <p className={styles.body}>
            I couldn't be happier with the home cleaning service provided by
            Fecho Intel. The attention to detail is incredible, and my house has
            never looked better. The team is efficient, trustworthy, and truly
            dedicated to making your living space spotless. I highly recommend
            their services!
          </p>
          <p>
            <i>- Sarah M.</i>
          </p>
        </div>
        {/* second */}
        <div className={styles.card}>
          <img src={quote} alt="" width={30} />
          <p className={styles.body}>
            As a business owner, I rely on the Labor Supply Services of Fecho
            Intel to meet my staffing needs. Their team consistently provides
            skilled and motivated workers, making it easy for me to focus on
            growing my business. Professional, reliable, and a true partner in
            success!
          </p>
          <p>
            <i>- Mark D.</i>
          </p>
        </div>
        {/* third */}
        <div className={styles.card}>
          <img src={quote} alt="" width={30} />
          <p className={styles.body}>
            Choosing Fecho Intel for my car repair was the best decision I made.
            The service was fast, the staff was knowledgeable, and my car is
            running like new. Their commitment to customer satisfaction is
            evident in every interaction. I won't trust my vehicle with anyone
            else.
          </p>
          <p>
            <i>- Emily R.</i>
          </p>
        </div>
      </Slider>
    </div>
  );
}

export default Testimonials;
