import styles from "./about.module.css";

function About() {
  return (
    <div
      className={`d-flex align-items-center justify-content-start ${styles.bg}`}
    >
      <div className="container">
        <h3 className={styles.head}>ABOUT US</h3>
        <h3 className={styles.company}>Fecho Intel</h3>
        <p className={styles.details}>
          At Fecho Intel, we embody a legacy of excellence in the petroleum
          industry. With a focus on innovation and reliability, our commitment
          to delivering superior products and services speaks for itself.
          Explore our journey as we continue to lead with trust, technology, and
          unwavering customer focus.
        </p>
      </div>
    </div>
  );
}

export default About;
