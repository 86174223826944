import fecho from "./../../assets/fecho.png";

function Navbar() {
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src={fecho} alt="" srcset="" width={25} />
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 700,
              color: "black",
              fontSize: "18px",
              marginLeft: "10px",
            }}
          >
            FECHO INTEL
          </span>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">
                About
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                Subscibe
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                Testimonial
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
