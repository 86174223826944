import styles from "./signin.module.css";
import fecho from "./../../assets/fecho.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import app from "./../../config/firebase";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const Login = () => {
    const auth = getAuth();
    setIsLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        // const user = userCredential.user;
        // console.log(user);
        navigate("/home");
        // console.log("login successful");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log("login unsuccessful");
        const errorMessage = error.message;
        setError("in");
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.signin}>
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.spinner}></div>
        </div>
      )}
      <div className={`container ${styles.main}`}>
        <img src={fecho} alt="" width={100} />
        <div className={styles.form}>
          <h2 className={styles.lead}>Login</h2>
          <input
            className={styles.field}
            type="text"
            placeholder="Email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <br />
          <input
            className={styles.field}
            type="password"
            placeholder="Password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <br />
          <input
            className={styles.submit}
            onClick={Login}
            type="submit"
            value="Login"
          />
        </div>

        <p style={{ marginTop: "30px" }}>
          New user?
          <Link className={styles.create} to="/signup">
            Create an account
          </Link>
        </p>
      </div>
      {error && (
        <div className={styles.message}>
          <span className={styles.red}>Invalid Email or Password</span>
        </div>
      )}
    </div>
  );
}

export default SignIn;
