import Carousel from "../carousel/Carousel";
import styles from "./offer.module.css";

function Offer() {
  return (
    <div className={styles.offer}>
      <div className={styles.intro}>
        <div>
          <h2 className={styles.lead}>WHAT WE OFFER</h2>
          <p>We offer the following services</p>
        </div>
        <div className={`container ${styles.carousel}`}>
          <Carousel />
        </div>
      </div>
    </div>
  );
}

export default Offer;
