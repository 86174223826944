import styles from "./subscribe.module.css";

function Subscribe() {
  return (
    <div className={`container-fluid ${styles.subscribe}`}>
      <div className={`container ${styles.main}`}>
        <p className={styles.lead}>Subscribe to our newsletter</p>
        <div className={styles.box}>
          <input
            className={styles.field}
            type="text"
            placeholder="Email Address"
          />
          <input className={styles.button} type="button" value="SUBSCRIBE" />
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
