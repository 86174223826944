import styles from "./footer.module.css";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.main_icons}>
        <div>
          <ion-icon name="logo-facebook"></ion-icon>
          <ion-icon name="logo-instagram"></ion-icon>
          <ion-icon name="logo-twitter"></ion-icon>
          <ion-icon name="logo-whatsapp"></ion-icon>
        </div>
        <p className={styles.lead}>joafechointel</p>
        <p className={styles.copy}>&copy;2024 codepanda</p>
      </div>
    </div>
  );
}

export default Footer;
