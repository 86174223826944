import styles from "./top.module.css";

function Top() {
  return (
    <div className={styles.top}>
      <div className="container d-flex justify-content-between">
        <div className={styles.left}>
          <div className={styles.details}>
            <ion-icon name="call-outline"></ion-icon>
            +2348056800198, +2348023399404
          </div>
          <div className={styles.details}>
            <ion-icon name="mail-outline"></ion-icon>
            joafechointel.ng.com
          </div>
        </div>
        <div className={styles.right}>
          <ion-icon name="logo-facebook"></ion-icon>
          <ion-icon name="logo-instagram"></ion-icon>
          <ion-icon name="logo-twitter"></ion-icon>
        </div>
      </div>
    </div>
  );
}

export default Top;
